import Main from '@/layout/main.vue'
import Blank from '@/layout/blank.vue'
const routes = [{
	path: '/user',
	name: 'user',
	meta: {
		title: '用户',
	},
	component: Main,
	redirect: '/user/lists',
	children: [
	    {
	        path: '/user/lists',
	        name: 'user_lists',
	        meta: {
	            title: '用户管理',
	            parentPath: '/user',
	            icon: 'icon_sort',
	            permission: ['view'],
	        },
	        component: () => import('@/views/user/lists.vue')
	    },
		{
			path: '/user/grade',
			name: 'user_grade',
			meta: {
				title: '用户等级',
				parentPath: '/user',
				icon: 'icon_user_dengji',
				permission: ['view'],
				keepAlive: true
			},
			component: () => import('@/views/user/grade.vue')
		},
		{
			path: '/user/grade_edit',
			name: 'user_grade_edit',
			meta: {
				hidden: true,
				title: '编辑等级',
				parentPath: '/user',
				prevPath: '/user/grade'
			},
			component: () => import('@/views/user/grade_edit.vue')
		}
	]
}]

export default routes
