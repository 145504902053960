import Main from '@/layout/main.vue'
import Blank from '@/layout/blank.vue'
const routes = [{
	path: '/marketing',
	name: 'marketing',
	meta: {
		title: '营销',
	},
	component: Main,
	redirect: '/member_price/index',
	children: [
		{
			path: '/member_price/index',
			name: 'member_price_index',
			meta: {
				title: '会员折扣',
				parentPath: '/marketing',
				icon: 'icon_kanjia',
				permission: ['view']
			},
			component: () => import('@/views/marketing/member_price/index.vue')
		},
		{
			path: '/member_price/edit',
			name: 'member_price_edit',
			meta: {
				hidden: true,
				title: '会员折扣',
				parentPath: '/marketing',
				prevPath: '/member_price/index',
				moduleName: 'member_price'
			},
			component: () => import('@/views/marketing/member_price/edit.vue')
		},
		{
			path: '/member_price/package_dis',
			name: 'package_dis',
			meta: {
				title: '套餐折扣',
				parentPath: '/marketing',
				icon: 'icon_kanjia',
				permission: ['view']
			},
			component: () => import('@/views/marketing/member_price/package_dis.vue')
		},
		{
			path: '/member_price/package_dis_edit',
			name: 'package_dis_edit',
			meta: {
				hidden: true,
				title: '套餐折扣',
				parentPath: '/marketing',
				moduleName: 'member_price',
				icon: 'icon_kanjia',
				permission: ['view']
			},
			component: () => import('@/views/marketing/member_price/package_dis_edit.vue')
		}
	]
}]

export default routes
