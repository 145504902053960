import Main from '@/layout/main.vue'
import Blank from '@/layout/blank.vue'
const routes = [{
	path: '/goods',
	name: 'goods',
	meta: {
		title: '商品',
	},
	component: Main,
	redirect: '/goods/lists',
	children: [
	    {
	        path: '/goods/lists',
	        name: 'goods_lists',
	        meta: {
	            title: '商品管理',
	            parentPath: '/goods',
	            icon: 'icon_sort',
	            permission: ['view'],
	        },
	        component: () => import('@/views/goods/lists.vue')
	    },
		{
			path: '/goods/release',
			name: 'goods_release',
			meta: {
				hidden: true,
				title: '新增商品',
				parentPath: '/goods',
				prevPath: '/goods/lists'
			},
			component: () => import('@/views/goods/release.vue')
		},
	    {
	        path: '/goods/category',
	        name: 'goods_category',
	        meta: {
	            title: '分类管理',
	            parentPath: '/goods',
	            icon: 'icon_sort',
	            permission: ['view']
	        },
	        component: () => import('@/views/goods/category.vue')
	    },
	    {
	        path: '/goods/category_edit',
	        name: 'category_edit',
	        meta: {
	            hidden: true,
	            title: '新增分类',
	            parentPath: '/goods',
	            prevPath: '/goods/category'
	        },
	        component: () => import('@/views/goods/category_edit.vue')
	    },
		{
		    path: '/goods/package',
		    name: 'goods_package',
		    meta: {
		        title: '商品套餐',
		        parentPath: '/goods',
		        icon: 'icon_gongyingshang',
		        keepAlive: true
		    },
		    redirect: '/goods/package/lists',
		    component: Blank,
		    children: [
		        {
		            path: '/goods/package/lists',
		            name: 'package_lists',
		            meta: {
		                title: '套餐管理',
		                parentPath: '/goods',
		                permission: ['view'],
		                keepAlive: true
		            },
		            component: () => import('@/views/goods/package/lists.vue')
		        },
		        {
		            path: '/goods/package/category',
		            name: 'package_category',
		            meta: {
		                title: '套餐分类',
		                parentPath: '/goods',
		                permission: ['view'],
		                keepAlive: true
		            },
		            component: () => import('@/views/goods/package/category.vue')
		        },
		        {
		            path: '/goods/package/edit',
		            name: 'package_edit',
		            meta: {
		                hidden: true,
		                title: '新增套餐',
		                parentPath: '/goods',
		                prevPath: '/goods/package/lists'
		            },
		            component: () => import('@/views/goods/package/edit.vue')
		        },
		        {
		            path: '/goods/package/item',
		            name: 'package_item',
		            meta: {
		                hidden: true,
		                title: '套餐条目',
		                parentPath: '/goods',
		                prevPath: '/goods/package/item'
		            },
		            component: () => import('@/views/goods/package/item.vue')
		        },
		        {
		            path: '/goods/package/item_edit',
		            name: 'package_item_edit',
		            meta: {
		                hidden: true,
		                title: '编辑套餐条目',
		                parentPath: '/goods',
		                prevPath: '/goods/package/item_edit'
		            },
		            component: () => import('@/views/goods/package/item_edit.vue')
		        },
		        {
		            path: '/goods/download_log',
		            name: 'download_log',
		            meta: {
		                hidden: true,
		                title: '下载记录',
		                parentPath: '/goods',
		                prevPath: '/goods/download_log'
		            },
		            component: () => import('@/views/goods/download_log.vue')
		        },
		    ]
		},
		
		{
			path: '/goods/unit',
			name: 'goods_unit',
			meta: {
				title: '商品单位',
				icon: 'icon_danwei',
				parentPath: '/goods',
				permission: ['view'],
				keepAlive: true
			},
			component: () => import('@/views/goods/unit.vue')
		},
		{
			path: '/goods/brand',
			name: 'goods_brand',
			meta: {
				title: '品牌管理',
				parentPath: '/goods',
				icon: 'icon_pinpai',
				permission: ['view'],
				keepAlive: true
			},
			component: () => import('@/views/goods/brand.vue')
		},
		{
			path: '/goods/brand_edit',
			name: 'brand_edit',
			meta: {
				hidden: true,
				title: '新增品牌',
				parentPath: '/goods',
				prevPath: '/goods/brand'
			},
			component: () => import('@/views/goods/brand_edit.vue')
		},
		{
			path: '/goods/supplier',
			name: 'goods_supplier',
			meta: {
				title: '供应商',
				parentPath: '/goods',
				icon: 'icon_gongyingshang'
			},
			redirect: '/goods/supplier/lists',
			component: Blank,
			children: [
				{
					path: '/goods/supplier/lists',
					name: 'supplier_lists',
					meta: {
						title: '供应商管理',
						parentPath: '/goods',
						permission: ['view']
					},
					component: () => import('@/views/goods/supplier/lists.vue')
				},
				{
					path: '/goods/supplier/category',
					name: 'supplier_category',
					meta: {
						title: '供应商分类',
						parentPath: '/goods',
						permission: ['view'],
						keepAlive: true
					},
					component: () => import('@/views/goods/supplier/category.vue')
				},
				{
					path: '/goods/supplier/edit',
					name: 'supplier_edit',
					meta: {
						hidden: true,
						title: '新增供应商',
						parentPath: '/goods',
						prevPath: '/goods/supplier/lists',
						keepAlive: true
					},
					component: () => import('@/views/goods/supplier/edit.vue')
				}
			]
		}
	]
}]

export default routes
